import React from 'react'
import Image from 'gatsby-image';

import "styles/HeroCraftsmen.scss";

const HeroCraftsmen = ({ fluidImage, title, className = '', column = true, useH1 = false }) => {
  return (
    <section className={`hero craftsmen heroComponent ${className} ${!column ? "no-column" : ""}`}>
      <Image
        loading="eager"
        fadeIn={false}
        fluid={fluidImage}
        className="hero-image"
      />
      
    </section>
  )
}

export default HeroCraftsmen;
