import { graphql } from 'gatsby'
import React from 'react'
import Image from 'gatsby-image'

import Layout from 'components/Layout'
import SEO from 'components/SEO';
import Content from 'components/Content';
import Cases from 'components/Cases';
import HeroCraftsmen from '../components/HeroCraftsmen';

import "styles/CraftsmenTemplate.scss";

const Craftsmen = ({ data, location }) => {
  const { post } = data
  const { acf: { banner_detail, intro, content } } = post;
  const url = location.href ? location.href : '';

  return (
    <Layout>
      <SEO yoast={{ meta: post.yoast_meta }} image={banner_detail.image.localFile.publicURL}  pathname={url} />

      <div className="craftsmen-page">
        <HeroCraftsmen
          className="klantcase-hero"
          fluidImage={banner_detail.image.localFile.childImageSharp.fluid}
        />

      <section className="intro">
        <div className="container">
          <div className="row pb-5">
            <div className="col-lg-6">
              <Content content={intro.title} className="mb-4" />
              <table cellPadding="3" className="intro-table mb-4 mb-lg-0">
                <tbody>
                  <tr>
                    <td>Name:</td>
                    <td>{intro.description.name}</td>
                  </tr>
                  <tr>
                    <td>Age:</td>
                    <td>{intro.description.age}</td>
                  </tr>
                  <tr>
                    <td>Function:</td>
                    <td>{intro.description.functie}</td>
                  </tr>
                  <tr>
                    <td>Shipyard:</td>
                    <td>{intro.description.shipyard}</td>
                  </tr>
                  <tr>
                    <td>Last project:</td>
                    <td>{intro.description.last_project}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-6">
              <Image fluid={intro.image.localFile.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </section>

      <section className="description">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Content content={content.description_one} />
            </div>
          </div>
        </div>
      </section>

      <section className="quote">
          <div className="container-fluid">
            <div className="row justify-content-center pt-lg-5">
              <div className="col-md-10">
                <Content className="quote-content" content={content.quote.content} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12 quote-person">
                <p>{content.quote.author.name}</p>
                <p>{content.quote.author.function}</p>
              </div>
            </div>
          </div>
        </section>

        <section className="description">
          <div className="container">
            <div className="row py-5 align-items-center">
              <div className="col-lg-6">
                <Content content={content.description_two} />
              </div>
              <div className="col-lg-6">
                <Image fluid={content.image.localFile.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </section>

      </div>
    </Layout>
  )
}

export default Craftsmen

export const query= graphql`
  query CraftmenByID($wordpress_id: Int!) {
    post: wordpressWpCraftsmen(
      wordpress_id: { eq: $wordpress_id }
    ){
      slug
      path
      title

      yoast_meta {
        name
        content
        property
      }

      acf {
        banner_detail {
          image {
            localFile {
              publicURL
              childImageSharp {
                fluid(quality: 99, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        
        intro {
          title
          description {
            name
            age
            functie
            shipyard
            last_project
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        content {
          description_one
          description_two
          quote {
            content
            author {
              name
              function
            }
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

      }
    }
  }
`